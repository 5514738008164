


















































































import { Component, Prop, Vue } from "vue-property-decorator";
import InformationBox from "@/components/InformationBox.vue";

@Component({
  name: "HelpBlock",
  components: {
    InformationBox,
  },
})
export default class HelpBlock extends Vue {
  @Prop() public block: any;

  public richText(entries: any) {
    if (!entries) return "";
    return entries.map((entry: any) => this.renderRichText(entry)).join("");
  }

  public renderRichText(entry: any) {
    const annotations = entry.annotations;
    const tags: String[] = [];
    if (annotations.bold) tags.push("b");
    if (annotations.italic) tags.push("i");
    if (annotations.underline) tags.push("u");
    if (annotations.strikethrough) tags.push("s");
    if (annotations.code) tags.push("code");

    const text: String[] = [];
    for (const tag of tags) {
      text.push(`<${tag}>`);
    }
    if (entry.href) {
      text.push(`<a class="block-link" href="${entry.href}" target="_blank">`);
    }
    if (annotations.color !== "default") {
      text.push(`<span class="block-color-${annotations.color}">`);
    }
    text.push(entry.plain_text.replace(/\n/g, "<br>"));
    if (annotations.color !== "default") {
      text.push(`</span>`);
    }
    if (entry.href) {
      text.push(`</a>`);
    }
    for (const tag of tags.reverse()) {
      text.push(`</${tag}>`);
    }
    return text.join("");
  }

  public linkToBlock(name: string, id: string) {
    // normalize name with only ascii character, replace with dash
    // finish with the id without dash
    name = name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-/, "")
      .replace(/-$/, "");
    id = id.replace(/-/g, "");
    return `/help/${name}-${id}`;
  }
}
