



































































import { Component, Watch, Vue } from "vue-property-decorator";
import { apiHead, apiGet } from "@/store";
import HelpBlock from "@/components/HelpBlock.vue";

@Component({
  components: {
    HelpBlock,
  },
})
export default class HelpButton extends Vue {
  public haveHelp = false;
  public blocks: any[] = [];
  public blockSlug = "";
  public openDialog = false;
  public slide = "style";
  public loading = false;

  get hasDivider() {
    return this.blocks.filter((block) => block.type === "divider").length > 0;
  }

  get blocksBySection() {
    const sections: any[] = [];
    let section: any[] = [];
    for (const block of this.blocks) {
      if (block.type === "divider") {
        sections.push({ id: "section-" + section[0].id, blocks: section });
        section = [];
      } else {
        section.push(block);
      }
    }
    sections.push({ id: "section-" + section[0].id, blocks: section });
    this.slide = sections[0].id;
    return sections;
  }

  @Watch("$route")
  onRouteChange() {
    let slug = this.$route.path;
    this.haveHelp = false;
    this.blocks = [];
    if (slug.startsWith("/help")) {
      this.haveHelp = false;
      return;
    }
    apiGet(`/help-exists${slug}`).then((response: any) => {
      if (slug == this.$route.path) {
        this.haveHelp = response["status"] == "ok";
      }
    });
  }

  showHelp() {
    this.openDialog = true;
    this.loadPage();
  }

  loadPage() {
    const slug = this.$route.path;

    if (slug === this.blockSlug) {
      return;
    }

    if (slug.startsWith("/help")) {
      return;
    }

    this.loading = true;
    apiGet(`/help${slug}`)
      .then((res: any) => {
        if (slug === this.$route.path) {
          this.loading = false;
          this.blocks = res["children"];
          this.blockSlug = slug;
        }
      })
      .catch(() => {
        if (slug === this.$route.path) {
          this.loading = false;
          this.blocks = [];
          this.blockSlug = slug;
        }
      });
  }

  mounted() {
    this.onRouteChange();
  }
}
