











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class InformationBox extends Vue {
  @Prop() public icon!: string;
  @Prop() public content!: string;
  @Prop() public alert!: boolean;
}
