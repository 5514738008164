





























































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { SocketIOMixin } from '../io-utils';
import HelpButton from '../components/HelpButton.vue';
import * as Sentry from '@sentry/browser';

@Component({
  components: {
    HelpButton,
  },
})
export default class DefaultView extends Mixins(SocketIOMixin) {
  public $store: any;
  public $q: any;
  public leftDrawerOpen: boolean = false;
  private preloadCount: number = 0;
  private timeoutId: any = null;

  public created() {
    this.leftDrawerOpen = this.$q.platform.is.desktop;
  }

  public mounted() {
    const socket: any = this.ioListen('/');
    this.preloadCount = 0;
    this.tryPreload();
  }

  public beforeDestroy() {
    this.ioClose();
  }


  public destroyed() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
  }

  public tryPreload() {
    this.timeoutId = null;
    this.$store.dispatch('preload')
      .then((response: any) => {
        this.$store.commit('setReady');
        this.setupNotifications();
      })
      .catch((error: any) => {
        this.preloadCount += 1;
        this.timeoutId = setTimeout(() => {
          this.tryPreload();
        }, 2000);
        Sentry.captureException(error);
      });
  }

  public logout() {
    this.$store.dispatch('logout');
    this.$router.push({ name: 'login', params: { msg: 'LOGOUT_SUCCESS' } });
  }

  public setupNotifications() {
    if (!this.$can('read', 'notification')) {
      return;
    }
    const socket: any = this.ioListen('/notifications');
    socket.on('new', (message: any) => {
      console.log('notification new', message);
      this.$store.dispatch('loadNotifications');
    });
    socket.on('ack', (message: any) => {
      console.log('notification ack', message);
    });
    socket.on('delete', (message: any) => {
      console.log('notification delete', message);
    });
    socket.on('ack-all', (message: any) => {
      console.log('notification ack-all', message);
    });
    socket.on('delete-all', (message: any) => {
      console.log('notification delete-all', message);
    });
    this.$store.dispatch('loadNotifications');
  }

  public gotoNotificationUrl(notification: any) {
    let url = `/notifications#nid-${notification.id}`;
    if (notification.url !== null) {
      url = notification.url;
    }
    this.$store.dispatch('notificationRead', notification.id);
    if (url[0] === '/') {
      this.$router.push({ path: url });
    } else {
      window.location.href = url;
    }
  }
}
